<template>
  <div
    :class="isActive == true ? 'questionContainer active' : 'questionContainer'"
    ref="questionContainer"
  >
    <div
      class="loadingContainer"
      :style="dataLoaded == false ? 'display:flex' : 'display:none'"
    >
      <div class="loader"></div>
      <div class="loadingText">Loading Question...</div>
    </div>
    <div class="questionNumber">
      {{
        this.InstanceData.questionTitle == null
          ? "Question"
          : this.InstanceData.questionTitle
      }}
      {{ questionNumber }}
    </div>
    <div class="questionText">{{ questionText }}</div>
    <div v-for="(option, index) in options" :key="index" class="questionOption">
      <div class="checkboxContainer">
        <input
          type="checkbox"
          v-model="option.checked"
          @change="dataChanged($event, index)"
        />
        <span class="checkmark"></span>
      </div>
      <div class="optionText">{{ option.text }}</div>
    </div>
    <div
      :class="
        options.some((x) => x.checked == true) || InstanceData.allowEmpty
          ? 'submitButton'
          : 'submitButton inactive'
      "
      @click="submitResponse"
      ref="submitButton"
      :style="reponseUploading == false ? 'display:block' : 'display:none'"
    >
      Submit
    </div>
    <div
      class="loader submitBtnLoader"
      :style="reponseUploading == true ? 'display:block' : 'display:none'"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    InstanceData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dataLoaded: false,
      reponseUploading: false,
      questionNumber: 1,
      questionText: "",
      options: [],
      isActive: false,
      questionTitle: "Question",
    };
  },
  methods: {
    submitResponse() {
      if (this.initialPreferences.googleAnalyticsTrackingCode != null) {
        this.$gtag.event("AssessmentQuestion", {
          data: new Date(),
          event_category: "User interaction",
          project_primarytitle: this.initialPreferences.PrimaryProjectTitle,
          project_secondarytitle: this.initialPreferences.SecondaryProjectTitle,
          device:this.initialPreferences.deviceDetails,
          value: "Submitted",
        });
      }
      this.reponseUploading = true;
      this.$refs.submitButton.style.pointerEvents = "none";
      var formBody = [
        encodeURIComponent("Id") +
          "=" +
          encodeURIComponent(this.InstanceData.questionId),
      ];
      this.options.map((option) => {
        if (option.checked == true) {
          formBody.push(
            encodeURIComponent("names") + "=" + encodeURIComponent(option.text)
          );
        }
      });
      formBody = formBody.join("&");
      fetch("https://interactive-eular.com/api/add-quiz-data-from-id", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: formBody,
      })
        .then(() => {
          this.reponseUploading = false;
          this.$refs.submitButton.style.pointerEvents = "unset";
        })
        .catch((e) => {
          alert("Error sending question data. Error: " + e.message);
        });
      this.$emit("setChildInactive", this);
    },
    setActive() {
      this.dataLoaded = false;
      fetch(
        this.initialPreferences.pollServerQuestionSender +
          this.InstanceData.questionId
      )
        .then((response) => response.json())
        .then((data) => {
          this.questionNumber = data.question
            .substr(0, data.question.indexOf(" "))
            .match(/\d+/)[0];
          this.questionText = data.question.substr(
            data.question.indexOf(" ") + 1,
            data.question.length
          );
          this.options = data.Options.map((text) => ({
            checked: false,
            text: text,
          }));
          this.dataLoaded = true;
        });
      this.isActive = true;

      if (this.initialPreferences.googleAnalyticsTrackingCode != null) {
        this.$gtag.event("AssessmentQuestion", {
          data: new Date(),
          event_category: "Initialisation",
          project_primarytitle: this.initialPreferences.PrimaryProjectTitle,
          project_secondarytitle: this.initialPreferences.SecondaryProjectTitle,
          device:this.initialPreferences.deviceDetails,
          value: "Showing",
        });
      }
    },
    setInActive() {
      this.isActive = false;
    },
    dataChanged(e, index) {
      if (this.InstanceData.allowMultiple == true) return;
      for (var i = 0; i < this.options.length; i++) {
        if (i != index || e.returnValue == false) {
          this.options[i].checked = false;
        }
      }
    },
  },
};
</script>

<style>
</style>